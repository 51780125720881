<template>
  <div class="">
    <!--搜索条件区域-->
    <div class="searchWrapper" @keydown.enter.prevent="searchData">
      <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
      <el-form
        :inline="true"
        label-position="right"
        :rules="rules"
        ref="formInline"
        :model="formInline"
        class="demo-form-inline"
      >
        <div class="col_box">
          <div class="col_left">
            <el-form-item :label="$t('searchModule.plate_number')" prop="plateNumber">
              <el-select
                v-model.trim="formInline.carId"
                filterable
                placeholder=""
                size="15"
              >
                <el-option label="全部" value=""></el-option>
                <el-option
                  :label="item.plateNumber"
                  :value="item.carId"
                  :key="item.carId"
                  v-for="item in carIdList"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('searchModule.region')">
              <!-- <el-select v-model.trim="formInline.areaId"
                     filterable
                     size="8">
            <el-option label="全部"
                       value=""></el-option>
            <el-option :label="item.areaName"
                       :value="item.areaId"
                       :key="item.areaId"
                       v-for="item in areaList"></el-option>
          </el-select> -->
              <a-cascader
                ref="cascader"
                @change="handleAreaChange"
              ></a-cascader>
            </el-form-item>
            <el-form-item :label="$t('searchModule.park_name')" prop="parkId">
              <my-component
                ref="parkInput"
                @valueChange="completeValue"
                slaveRelations="0,1"
              ></my-component>
              <!-- <el-select remote :remote-method="parkData"  v-model.trim="formInline.parkId" filterable placeholder="请输入内容" size="15">
          <el-option :label="park.parkName" :value="park.parkId" :key="park.parkId" v-for="park in parkList"></el-option>
        </el-select> -->
              </el-form-item>
              <el-form-item :label="$t('searchModule.Entry_and_exit_types')" prop="entryOrExit">
                <el-select v-model.trim="formInline.entryOrExit" filterable size="10">
                  <el-option
                    :label="item.desc"
                    :value="item.code"
                    :key="item.code"
                    v-for="item in ExitTypeList"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Date_search')">
                <el-date-picker
                  v-model="timeArray"
                  type="datetimerange"
                  range-separator="~"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  :default-time="['00:00:00', '23:59:59']"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                />
                <i class="el-icon-date el-icon-date-custom"></i>
              </el-form-item>
              <!-- <el-form-item :label="$t('searchModule.time_slot')">
        <el-date-picker v-model="date1" type="datetime" placeholder="选择日期" @change="showLog"> </el-date-picker> 至
        <el-date-picker v-model="date2" type="datetime" placeholder="选择日期" @change="showLog"> </el-date-picker>
      </el-form-item> -->
            </div>
            <div class="col_right">
              <AuthorityComponent
                ComponentName="el-button"
                :permission="['subPage.view.tabs.debtList.button.query']"
                type="primary"
                icon="el-icon-search"
                :loading="loading"
                @click="
                  pageNum = 1;
                  searchData();
                "
                >查询
              </AuthorityComponent>
            </div>
          </div>
          <div class="col_box_boder"></div>
          <!-- 如果没有下面按钮就不需要 -->
          <div class="col_box h44">
            <div class="col_left">
              <!-- 其他按钮 -->
            </div>
            <div class="col_right mbd4">
              <AuthorityComponent
                ComponentName="el-button"
                :permission="['subPage.view.tabs.debtList.button.export']"
                type="info"
                icon="el-icon-upload2"
                :loading="loading"
                @click="
                  pageNum = 1;
                  exportFile();
                "
                >导出
              </AuthorityComponent>
            </div>
          </div>
          <!-- <el-button type="primary" icon="el-icon-delete" @click="resetForm('formInline')">{{ $t('button.reset') }}</el-button> -->

          <!--<el-button type="primary" icon="plus" @click="">{{ $t('button.addto') }}</el-button>-->
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper bgFFF paddingB10">
        <el-table v-loading="loading" :data="tableData" style="width: 100%">
          <!--<el-table-column type="index" :label="$t('list.index')" width="70"></el-table-column>-->
          <el-table-column
            align="center"
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
          ></el-table-column>
        </el-table>
        <!--分页器-->
        <div class="pagerWrapper">
          <div class="block">
            <el-pagination
              v-if="total != 0"
              @current-change="handleCurrentChange"
              :current-page="pageNum"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  // setIndex,
  dateFormat,
  exportExcelNew,
  setIndex,
} from "../../../common/js/public.js";
import myComponent from "@/components/autocomplete/myautoComponent";
export default {
  props: ["authority"],
  data() {
    const startTime =
      this.$moment().subtract(30, "d").format("YYYY-MM-DD") + " 00:00:00";
    const endTime = this.$moment().format("YYYY-MM-DD") + " 23:59:59";
    let checkParkNum = (rule, value, callback) => {
      let len = value.length;
      if (!value) {
        callback();
      } else if (len < 3) {
        callback(new Error("至少输入三个字符"));
      } else if (len > 3 && len <= 11) {
        callback();
      } else if (len > 11) {
        callback(new Error("最多输入11个字符"));
      }
    };
    let checkPhone = (rule, value, callback) => {
      if (!/^\d{11}$/.test(value) && value) {
        callback(new Error("手机号格式不正确"));
      } else {
        callback();
      }
    };
    return {
      carIdList: [],
      activeName: "1",
      ExitTypeList: [],
      memberInfo: {},
      carList: [],
      index: 0,
      total: 0,
      plateNumber: "",
      pageSize: 15,
      pageNum: 1,
      loading: false,
      detailFlag: false,
      isDisabled: true,
      areaList: [],
      parkList: [],
      berthList: [],
      tableCols: [
        {
          prop: "mobile",
          label: "会员手机号",
        },
        {
          prop: "plateNumber",
          label: this.$t("list.plate_number"),
          width: "",
          formatter: (row, column) => {
            if (row.plateNumber.indexOf("无") != -1) {
              return "无牌车";
            } else {
              return row.plateNumber;
            }
          },
        },
        {
          prop: "areaName",
          label: this.$t("list.region"),
        },
        {
          prop: "parkName",
          label: this.$t("list.park_name"),
          width: "",
        },
        {
          prop: "berthCode",
          label: this.$t("list.Berth_number"),
        },
        {
          prop: "entryTime",
          label: this.$t("list.entry_time"),
          width: "",
        },
        {
          prop: "exitTime",
          label: this.$t("list.Appearance_time"),
          width: "",
        },
        {
          prop: "shouldPay",
          label: "应缴金额(元)",
          width: "",
          formatter: (row) => {
            if (row.shouldPay) {
              return Number(row.shouldPay / 100).toFixed(2);
            } else {
              return "0.00";
            }
          },
        },
        {
          prop: "parkTime",
          label: "停车时长(分钟)",
        },
        {
          prop: "debtDays",
          label: "欠费天数(天)",
        },
      ],
      tableData: [],
      dateRange: [],
      payTypeList: [],
      formInline: {
        carId: "",
        parkId: "",
        plateNumber: "",
        entryOrExit: 1,
        areaId: "",
      },
      rules: {
        plateNumber: [
          {
            validator: checkParkNum,
            trigger: "blur",
          },
        ],
        mobile: [
          {
            validator: checkPhone,
            trigger: "blur",
          },
        ],
      },
      timeArray: [startTime, endTime],
    };
  },
  components: {
    myComponent,
  },
  methods: {
    // 选择区域
    handleAreaChange() {
      let areaId = "";
      if (this.$refs.cascader) {
        areaId = this.$refs.cascader.getStreetId()
          ? this.$refs.cascader.getStreetId()
          : this.$refs.cascader.getAreaId();
      }
      this.formInline.areaId = areaId;
    },
    // 加载区域列表
    initAreaList() {
      const opt = {
        url: "/acb/2.0/systems/loginUser/initAreaList",
        method: "get",
        data: {},
        success: (res) => {
          this.areaList = res.value.areaList;
        },
      };
      this.$request(opt);
    },
    // 获取支付方式
    getPay() {
      let opt = {
        method: "get",
        url: "/acb/2.0/recharge/rechargeType/list",
        data: {},
        success: (res) => {
          if (res.state == 0) {
            this.payTypeList = res.value;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        },
      };
      this.$request(opt);
    },
    completeValue(item) {
      this.formInline.parkId = item.parkId;
      this.formInline.parkName = item.parkName;
    },
    /* 停车场 */
    parkData(queryString) {
      if (!queryString) {
        this.formInline.parkId = "";
        return;
      }
      this.$axios
        .get("/acb/2.0/systems/loginUser/getParkName", {
          data: {
            page: 0,
            pageSize: 20,
            areaIds: "",
            slaveRelations: "0,1",
            parkName: queryString,
          },
        })
        .then((res) => {
          this.parkList = res.value.list;
        });
    },
    querySearchAsync(queryString, cb) {
      this.loading = false;
      if (queryString.length < 3) {
        cb([]);
        return;
      }
      let opt = {
        method: "get",
        url: "/acb/2.0/parkRecord/plateNumber/list",
        data: {
          part: queryString,
          size: 100,
        },
        success: (res) => {
          if (res.state == 0) {
            res.value.forEach((v) => {
              v.value = v.plateNumber;
            });
            cb(res.value);
          }
        },
      };
      this.$request(opt);
    },
    getCarlist() {
      this.$axios
        .get("/acb/2.0/member/getPlateByMemberId/" + this.memberInfo.memberId)
        .then((res) => {
          this.carIdList = res.value;
        });
    },
    // resetForm (formName) {
    //   let date1 = new Date();
    //   date1.setHours(0);
    //   date1.setMinutes(0);
    //   date1.setSeconds(0);
    //   let startTime = date1;
    //   let date = new Date();
    //   date.setHours(23);
    //   date.setMinutes(59);
    //   date.setSeconds(59);
    //   let endTime = date;
    // 	this.date1 = startTime;
    // 	this.date2 = endTime;
    //   this.formInline = {
    //       carId: '',
    //       parkId: '',
    // 		  plateNumber: '',
    //       entryOrExit: 1
    //   };
    //   this.$refs.parkInput.setShowVal('');
    //   this.$refs[formName].resetFields();
    //   this.formInline.memberId = this.memberInfo.memberId;
    // },
    handleSelect(item) {
      this.loading = false;
      this.formInline.plateNumber = item.plateNumber;
      this.formInline.carId = item.carId;
      this.pageNum = 1;
      // this.searParkRecordList();
    },
    // 导出
    exportFile() {
      // let flag = this.showLog();
      // if (flag) {
      //   exportExcelNew('/acb/2.0/member/debtOrder/export', this.formInline);
      // }
      if (!this.showLog()) {
        return;
      }
      this.formInline.startTime = this.timeArray[0];
      this.formInline.endTime = this.timeArray[1];
      exportExcelNew("/acb/2.0/member/debtOrder/export", this.formInline);
    },
    // 处理操作相关动作
    ctrlData(command, index, obj) {
      //				let url;
      if (command == "wf") {
        this.downloadSys(obj);
      } else if (command == "df") {
        this.downloadChannel(obj);
      } else if (command == "differ") {
        this.downloadDif(obj);
      }
    },
    // 验证时间
    showLog() {
      if (!this.timeArray) {
        this.$alert("日期不能为空", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return false;
      }
      if (this.timeArray[0] > this.timeArray[1]) {
        this.$alert("结束时间不能小于开始时间", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return false;
      }
      // const startTime = this.$moment(this.timeArray[0]).unix()
      // const endTime = this.$moment(this.timeArray[1]).unix()
      // if ((endTime - startTime) / 3600 / 24 > 31) {
      //   this.$alert('查询时间不能大于31天', this.$t('pop_up.Tips'), {
      //     confirmButtonText: this.$t('pop_up.Determine')
      //   })
      //   return false
      // }
      return true;
    },
    // 出入场类型列表
    getExitType() {
      let opt = {
        url: "/acb/2.0/parkRecord/entryExitType/list",
        method: "get",
        data: {},
        success: (res) => {
          if (res.state == 0) {
            this.ExitTypeList = res.value;
          } else {
            this.$alert("获取出入场类型失败", this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        },
      };
      this.$request(opt);
    },

    handleCurrentChange(val) {
      this.pageNum = val;
      this.searchData();
    },
    // 搜索
    searchData() {
      if (!this.showLog()) {
        return;
      }
      this.loading = true;
      this.$refs.parkInput.setShowVal(this.formInline.parkName);
      const startDate = this.timeArray[0];
      const endDate = this.timeArray[1];
      let opt = {
        method: "get",
        url: "/acb/2.0/member/debtOrder/list",
        data: {
          page: this.pageNum,
          pageSize: this.pageSize,
          memberId: this.memberInfo.memberId,
          startTime: startDate,
          endTime: endDate,
          carId: this.formInline.carId,
          parkId: this.formInline.parkId,
          entryOrExit: this.formInline.entryOrExit,
          areaId: this.formInline.areaId,
          // operationId: this.formInline.operationId,
          plateNumber: this.formInline.plateNumber,
        },
        success: (res) => {
          this.loading = false;
          if (res.state == 0) {
            if (!res.value) {
              return;
            }
            this.tableData = setIndex(this.pageNum, res.value.list);
            this.total = res.value.total * 1 || 0;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        },
        fail: (err) => {
          this.loading = false;
        },
      };
      this.$request(opt);
    },
  },
  created() {
    this.memberInfo = JSON.parse(this.$route.query.data);
    this.formInline.memberId = this.memberInfo.memberId;
    if (this.$route.query.carList) {
      this.carList = JSON.parse(this.$route.query.carList);
    }
  },
  mounted() {
    this.searchData();
    // this.parkData();
    this.initAreaList();
    this.getExitType();
    this.getCarlist();
  },
  computed: {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.breadcrumb {
  height: 35px;
}

.content {
  background: #FFFFFF;
  overflow: hidden;

  .chartsWrapper {
    height: 360px;
    margin-bottom: 20px;

    .wrapper {
      background: #FFFFFF;
      border: 1px solid #C0CCDA;
      border-radius: 4px;

      .title {
        font-size: 14px;
        color: #475669;
        padding-left: 17px;
        height: 50px;
        line-height: 50px;
      }
    }
  }

  .searchWrapper {
    overflow: hidden;
    // background: #EFF2F7;
    // padding: 22px 22px 0;
    // margin-bottom: 20px;
  }

  .pagerWrapper {
    text-align: right;
    margin-top: 28px;
    font-size: 12px;

    .export {
      font-size: 12px;

      .iconfont {
        margin-right: 0;
      }
    }
  }
}
</style>
<style scoped>
.info {
  width: 505px;
}

.member-basic-info {
  width: 505px;
}

.info li {
  overflow: hidden;
  border-left: 1px solid #ebeef5;
  border-top: 1px solid #ebeef5;
}

.info li div {
  float: left;
  min-height: 30px;
  line-height: 30px;
  border-right: 1px solid #ebeef5;
  border-bottom: 1px solid #ebeef5;
}

.title {
  line-height: 50px;
  border: 1px solid #ebeef5;
  border-bottom: 0;
  padding-left: 10px;
  color: skyblue;
}

.item-name {
  width: 100px;
  text-align: right;
}

.item-img {
  width: 250px;
  text-align: center;
  vertical-align: middle;
}

.item-img img {
  width: 80%;
  vertical-align: middle;
}

.item-content {
  width: 150px;
  text-align: center;
}

.balance {
  display: inline-block;
  color: white;
  padding: 2px 15px;
  line-height: 18px;
}

.item-img-wrapper {
  min-height: 124px !important;
  line-height: 124px !important;
}

.item-img-wrapper .item-name,
.item-img-wrapper .item-content,
.item-img {
  height: 124px !important;
  line-height: 124px !important;
}
</style>
