<template>
  <div class="page1">
    <!--面包屑-->
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper" @keydown.enter.prevent="searchData">
        <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
        <el-form
          :inline="true"
          label-position="right"
          :model="formInline"
          class="demo-form-inline"
        >
          <div class="col_box">
            <div class="col_left">
              <!-- <el-form-item :label="$t('searchModule.plate_number')" prop="plateNumber">
              <el-autocomplete
                valueKey='plateNumber'
                class="inline-input"
                v-model.trim="plateNumber"
                :fetch-suggestions="querySearchAsync"
                placeholder="请输入内容"
                :trigger-on-focus="false"
                @select="handleSelect"
              ></el-autocomplete>
          </el-form-item> -->
              <el-form-item :label="$t('searchModule.plate_number')" prop="plateNumber">
                <el-select
                  v-model.trim="formInline.carId"
                  filterable
                  placeholder=""
                  size="15"
                >
                  <el-option label="全部" value=""></el-option>
                  <el-option
                    :label="item.plateNumber"
                    :value="item.carId"
                    :key="item.carId"
                    v-for="item in carIdList"
                  />
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.park_name')">
                <my-component
                  ref="parkInput"
                  :areaIds="
                    formInline.streetId
                      ? formInline.streetId
                      : formInline.areaId
                  "
                  :operationId="formInline.operationId"
                  @valueChange="completeValue"
                  slaveRelations="0,1"
                >
                </my-component>
              </el-form-item>
              <!-- <el-form-item :label="$t('searchModule.Payment_method')">
            <el-select
              v-model.trim="formInline.payType"
              filterable
              placeholder=""
              size="12"
            >
              <el-option label="全部" value=""></el-option>
              <el-option
                :label="pay.payTypeName"
                :value="pay.payType"
                :key="pay.payType"
                v-for="pay in payTypeList"
              ></el-option>
            </el-select>
          </el-form-item> -->
              <el-form-item :label="$t('searchModule.Payment_channels')" prop="channelType">
                <el-select v-model.trim="formInline.channelType">
                  <el-option label="全部" value=""></el-option>
                  <el-option
                    :label="value.channelTypeDesc"
                    :value="value.channelType"
                    :key="value.channelType"
                    v-for="value in channelList"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <!-- <el-form-item :label="$t('searchModule.Payment_devices')" prop="devType">
            <el-select v-model.trim="formInline.devType">
              <el-option label="全部" value=""></el-option>
              <el-option
                :label="value.desc"
                :value="value.code"
                :key="value.code"
                v-for="value in plantList"
              >
              </el-option>
            </el-select>
          </el-form-item> -->
              <!-- <el-form-item>
            <el-select v-model.trim="formInline.timeConditionType" filterable>
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item> -->
              <el-form-item :label="$t('searchModule.Payment_Time')">
                <!-- <el-date-picker v-model="date1" type="datetime" placeholder="选择日期" @change="showLog"> </el-date-picker> -
            <el-date-picker v-model="date2" type="datetime" placeholder="选择日期" @change="showLog">  </el-date-picker> -->
                <el-date-picker
                  v-model="timeArray"
                  type="datetimerange"
                  range-separator="~"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  :default-time="['00:00:00', '23:59:59']"
                >
                </el-date-picker>
                <i class="el-icon-date el-icon-date-custom"></i>
              </el-form-item>
            </div>
            <div class="col_right">
              <AuthorityComponent
                ComponentName="el-button"
                :permission="['subPage.view.tabs.userPayment.button.query']"
                type="primary"
                icon="el-icon-search"
                :loading="loading"
                @click="
                  pageNum = 1;
                  searchData();
                "
                >查询
              </AuthorityComponent>
            </div>
          </div>
          <div class="col_box_boder"></div>
          <!-- 如果没有下面按钮就不需要 -->
          <div class="col_box h44">
            <div class="col_left">
              <!-- 其他按钮 -->
            </div>
            <div class="col_right mbd4">
              <AuthorityComponent
                ComponentName="el-button"
                :permission="['subPage.view.tabs.userPayment.button.export']"
                type="info"
                icon="el-icon-upload2"
                :loading="loading"
                @click="ExportData()"
                >导出
              </AuthorityComponent>
            </div>
          </div>
          <!-- <el-button type="primary" @click="resetForm()">{{ $t('button.reset') }}</el-button> -->

          <!--<el-button type="primary" icon="plus" @click="">{{ $t('button.addto') }}</el-button>-->
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper">
        <el-table
          border
          v-loading="loading"
          :data="tableData"
          style="width: 100%"
        >
          <!--<el-table-column type="index" :label="$t('list.index')" width="70"></el-table-column>-->
          <el-table-column
            align="center"
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
            show-overflow-tooltip
          ></el-table-column>
        </el-table>
      </div>
      <!--分页器-->
      <div class="pagerWrapper">
        <div class="block">
          <el-pagination
            v-if="total != 0"
            @current-change="handleCurrentChange"
            :current-page="pageNum"
            :page-size="pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import myComponent from "@/components/autocomplete/myautoComponent";
import {
  dateFormat,
  setIndex,
  exportExcelNew,
} from "../../../common/js/public";
export default {
  props: ["authority"],
  name: "payRecordQuery",
  data() {
    const startTime =
      this.$moment().subtract(30, "days").format("YYYY-MM-DD") + " 00:00:00";
    const endTime = this.$moment().format("YYYY-MM-DD") + " 23:59:59";
    return {
      options: [
        {
          value: 1,
          label: this.$t("list.entry_time"),
        },
        {
          value: 0,
          label: this.$t("list.Appearance_time"),
        },
      ],
      index: 0,
      total: 0,
      pageSize: 15,
      plateNumber: "",
      pageNum: 1,
      loading: false,
      detailFlag: false,
      areaList: [],
      parkList: [],
      berthList: [],
      // payTypeList: [],
      channelList: [],
      // plantList: [],

      carIdList: [],
      tableCols: [
        {
          prop: "payOrderId",
          label: this.$t("list.order_ID"),
          width: "",
        },
        {
          prop: "parkName",
          label: this.$t("list.park_name"),
          width: "",
        },
        {
          prop: "berthNumber",
          label: this.$t("list.Berth_number"),
          width: "",
        },
        {
          prop: "plateNumber",
          label: this.$t("list.plate_number"),
          width: "",
        },
        {
          prop: "entryTime",
          label: this.$t("list.entry_time"),
          width: "",
        },
        {
          prop: "exitTime",
          label: this.$t("list.billable_hours"),
          width: "",
        },
        {
          prop: "strParkTime",
          label: this.$t("list.Parking_duration"),
          width: "",
        },
        {
          prop: "shouldPay",
          label: this.$t("list.amount_receivable"),
          width: "",
          formatter: (row, column) => {
            if (row.shouldPay) {
              return Number(row.shouldPay / 100).toFixed(2);
            } else {
              return "0.00";
            }
          },
        },
        {
          prop: "money",
          label: this.$t("list.Actual_received_amount"),
          width: "",
          formatter: (row, column) => {
            if (row.money) {
              return Number(row.money / 100).toFixed(2);
            } else {
              return "0.00";
            }
          },
        },
        {
          //   prop: 'payTypeDesc',
          //   label: '支付方式',
          //   width: ''
          // }, {
          prop: "channelTypeDesc",
          label: this.$t("list.Payment_channels"),
          width: "",
        },
        {
          prop: "devTypeDesc",
          label: this.$t("list.Payment_devices"),
          width: "",
          // ,
          // formatter: (row) => {
          //   if (row.devType == 1) {
          //     return '系统自动扣款'
          //   } else if (row.devType == 2) {
          //     return '会员APP支付'
          //   } else if (row.devType == 3) {
          //     return '会员微信支付'
          //   } else if (row.devType == 4) {
          //     return 'MS支付'
          //   } else if (row.devType == 5) {
          //     return 'PDA支付'
          //   } else if (row.devType == 6) {
          //     return '其他方式支付'
          //   } else if (row.devType == 7) {
          //     return 'TOC支付'
          //   } else if (row.devType == 8) {
          //     return '非会员微信公众号支付'
          //   }
          // }
        },
        {
          prop: "payTime",
          label: this.$t("list.payment_time"),
          width: "",
        },
        {
          prop: "operatorName",
          label: this.$t("list.Operator"),
          width: "",
        },
        {
          prop: "payParkName",
          label: this.$t("list.Payment_yard"),
          width: "",
        },
        {
          prop: "tradeNo",
          label: this.$t("list.Third_party_transaction_ID"),
          width: "",
        },
      ],
      tableData: [],
      formInline: {
        memberId: JSON.parse(this.$route.query.data).memberId,
        parkId: "",
        parkName: "",
        // payType: "",
        channelType: "",
        // devType: "",
        carId: "",
        // timeConditionType: 1,
      },
      timeArray: [startTime, endTime],
    };
  },
  watch: {
    plateNumber(newVal) {
      if (!newVal) {
        this.formInline.carId = "";
      }
    },
  },
  methods: {
    resetForm() {
      const startTime = this.$moment().format("YYYY-MM-DD") + " 00:00:00";
      const endTime = this.$moment().format("YYYY-MM-DD") + " 23:59:59";
      this.plateNumber = "";
      this.formInline = {
        areaId: "",
        streetId: "",
        operationId: "",
        parkId: "",
        parkType: "1",
        plateNumber: "",
        entryOrExit: 1,
        mobile: "",
        // timeConditionType: 1,
        // payType: "",
        channelType: "",
        // devType: "",
      };
      this.formInline.parkId = "";
      this.formInline.parkName = "";
      this.$refs.parkInput.setValue();
    },
    handleSelect(item) {
      this.formInline.plateNumber = item.plateNumber;
      this.formInline.carId = item.carId;
      // this.pageNum = 1;
      // this.searchData()
    },
    querySearchAsync(queryString, cb) {
      this.formInline.carId = "";
      queryString = queryString.replace(/\s/g, "");
      if (queryString.length < 3) {
        cb([]);
        return;
      }
      this.$axios
        .get("/acb/2.0/parkRecord/plateNumber/list", {
          data: {
            part: queryString,
            size: 100,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            res.value.forEach((v) => {
              v.value = v.plateNumber;
            });
            cb(res.value);
          }
        });
    },
    completeValue(item) {
      if (item) {
        this.formInline.parkId = item.parkId;
        this.formInline.parkName = item.parkName;
      } else {
        this.formInline.parkId = "";
        this.formInline.parkName = "";
      }
    },
    ExportData() {
      if (!this.showLog()) {
        return;
      }
      const opt = {
        // page: this.pageNum,
        // pageSize: this.pageSize,
        memberId: this.formInline.memberId,
        // timeConditionType: this.formInline.timeConditionType,
        startTime: this.timeArray[0],
        endTime: this.timeArray[1],
      };
      exportExcelNew("/acb/2.0/member/payOrderList/export", opt);
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.searchData();
    },
    // 验证时间
    showLog() {
      if (!this.timeArray) {
        this.$alert("日期不能为空", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return false;
      }
      if (this.timeArray[0] > this.timeArray[1]) {
        this.$alert("结束时间不能小于开始时间", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return false;
      }
      // const startTime = this.$moment(this.timeArray[0]).unix();
      // const endTime = this.$moment(this.timeArray[1]).unix();
      // if ((endTime - startTime) / 3600 / 24 > 31) {
      //   this.$alert("查询时间不能大于31天", this.$t('pop_up.Tips'), {
      //     confirmButtonText: this.$t('pop_up.Determine'),
      //   });
      //   return false;
      // }
      return true;
    },
    // 获取支付方式
    // getPay() {
    //   let opt = {
    //     method: "get",
    //     url: "/acb/2.0/payment/payTypeDictList",
    //     success: (res) => {
    //       if (res.state == 0) {
    //         this.payTypeList = res.value;
    //       } else {
    //         this.$alert(res.desc, this.$t('pop_up.Tips'), {
    //           confirmButtonText: this.$t('pop_up.Determine'),
    //         });
    //       }
    //     },
    //   };
    //   this.$request(opt);
    // },
    // 获取渠道列表
    getChannelList() {
      this.$axios
        .get("/acb/2.0/payOrder/payChannelTypeDictList")
        .then((res) => {
          if (res.state == 0) {
            this.channelList = res.value || [];
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },

    // 获取设备列表
    // getPlantList() {
    //   this.$axios.get("/acb/2.0/payOrder/devTypeList").then((res) => {
    //     if (res.state == 0) {
    //       this.plantList = res.value || [];
    //     } else {
    //       this.$alert(res.desc, this.$t('pop_up.Tips'), {
    //         confirmButtonText: this.$t('pop_up.Determine'),
    //       });
    //     }
    //   });
    // },

    searchData() {
      if (!this.showLog()) {
        return;
      }
      this.loading = true;
      const startDate = this.timeArray[0];
      const endDate = this.timeArray[1];
      let opt = {
        method: "get",
        url: "/acb/2.0/member/payOrderList",
        data: {
          page: this.pageNum,
          pageSize: this.pageSize,
          memberId: this.formInline.memberId,
          // payType: this.formInline.payType,
          channelType: this.formInline.channelType,
          // devType: this.formInline.devType,
          startTime: this.timeArray[0],
          endTime: this.timeArray[1],
          parkId: this.formInline.parkId,
          carId: this.formInline.carId,
          // timeConditionType: this.formInline.timeConditionType,
        },
        success: (res) => {
          this.tableData = [];
          this.loading = false;
          if (res.state == 0) {
            this.tableData = setIndex(this.pageNum, res.value.list);
            this.total = res.value.total * 1 || 0;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        },
        fail: (err) => {
          this.loading = false;
        },
      };
      this.$request(opt);
    },
    getCarlist() {
      this.$axios
        .get("/acb/2.0/member/getPlateByMemberId/" + this.formInline.memberId)
        .then((res) => {
          this.carIdList = res.value;
        });
    },
  },
  components: {
    myComponent,
  },
  activated() {
    // this.searchData();
  },
  mounted() {
    this.getCarlist();
    // this.getPay();
    this.getChannelList();
    // this.getPlantList();
  },
  computed: {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.breadcrumb {
  height: 35px;
}

.content {
  background: #FFFFFF;
  overflow: hidden;

  .pagerWrapper {
    text-align: right;
    margin-top: 28px;
    font-size: 12px;

    .export {
      font-size: 12px;

      .iconfont {
        margin-right: 0;
      }
    }
  }
}

.detail {
  width: 61.4%;
  height: 82.9%;
  position: absolute;
  top: 8.6%;
  left: 20.1%;
  background: #FFFFFF;
  box-shadow: 0 0 4px 1px rgba(128, 145, 165, 0.3);
  border-radius: 3px;
  z-index: 1000;
  box-sizing: border-box;
  padding: 20px 0;

  .title {
    position: absolute;
    background: #3F4A56;
    border-radius: 3px 3px 0 0;
    color: #fff;
    width: 100%;
    top: 0;
    left: 0;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    padding: 0 12px;
    box-sizing: inherit;
    z-index: 100;

    .el-icon-close {
      float: right;
      margin-top: 14px;
    }
  }

  .info {
    margin-top: 20px;
    padding: 2.1%;
    height: 100%;
    overflow: auto;
    box-sizing: inherit;
  }
}

.mask {
  background: rgba(49, 53, 65, 0.6);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
}
</style>
