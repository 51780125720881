<template>
  <div>
    <!--面包屑-->
    <div class="breadcrumb">
      <div style="text-align: right">
        <el-button @click="$router.go(-1)" type="primary">返回</el-button>
      </div>
    </div>
    <!--主体内容-->
    <div class="content">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <AuthorityComponent
          ComponentName="el-tab-pane"
          :permission="['subPage.view.tabs.baseInfo']"
          label="基本信息"
          name="1"
        >
          <div class="searchWrapper">
            <el-form
              :inline="true"
              :model="formInline"
              label-position="left"
              ref="searchWrapper"
              :rules="rules"
              label-width="70px"
              class="demo-form-inline"
              style="text-align: left; margin-left: -15px"
            >
              <div class="infoWrapper">
                <div class="fl" style="width: 20%">
                  <div
                    style="float: left; margin-left: 15px; margin-right: 10px"
                  >
                    用户头像
                  </div>
                  <div class="img" style="margin-left: 80px">
                    <img
                      :src="formInline.portrait"
                      alt=""
                      style="width: 160px; height: 160px"
                    />
                  </div>
                  <!-- <div class="t_c" style="height:40px;">{{memberInfo.nickName}}</div> -->
                  <!-- <div class="content"> <span>账户余额：</span>&yen<span class='showEmp' :title='(memberInfo.balance / 100).toFixed(2)'> {{(memberInfo.balance / 100).toFixed(2)}}</span></div> -->
                </div>
                <div class="fl" style="width: 70%; float: right">
                  <el-form-item label="昵称">
                    <el-input
                      :maxlength="11"
                      size="medium"
                      :disabled="true"
                      :hide-required-asterisk="true"
                      v-model.trim="formInline.nickName"
                      auto-complete="off"
                    />
                  </el-form-item>
                  <el-form-item :label="$t('searchModule.sex')">
                    <!-- <el-input :maxlength="11" size="medium" :hide-required-asterisk="true" v-model.trim="formInline.sexValue" v-text="formInline.sexValue == 1 ? '男' : '女'" auto-complete="off" placeholder="请输入性别">
                </el-input> -->
                    <el-input
                      :maxlength="11"
                      size="medium"
                      :disabled="true"
                      :hide-required-asterisk="true"
                      v-model.trim="formInline.sexValue"
                      auto-complete="off"
                    />
                    <!-- {{ formInline.sexValue }} -->
                  </el-form-item>
                  <el-form-item :label="$t('searchModule.phone_number')">
                    <el-input
                      :maxlength="11"
                      size="medium"
                      :disabled="true"
                      :hide-required-asterisk="true"
                      v-model.trim="formInline.mobile"
                      auto-complete="off"
                    />
                  </el-form-item>
                  <el-form-item label="年龄" prop="years">
                    <el-input
                      :maxlength="11"
                      size="medium"
                      :disabled="true"
                      :hide-required-asterisk="true"
                      v-model.trim="formInline.years"
                      auto-complete="off"
                    >
                    </el-input>
                  </el-form-item>
                  <el-form-item label="出生日期" prop="birthDate">
                    <el-input
                      :maxlength="11"
                      :disabled="true"
                      size="medium"
                      :hide-required-asterisk="true"
                      v-model.trim="formInline.birthDate"
                      auto-complete="off"
                    >
                    </el-input>
                  </el-form-item>
                  <el-form-item :label="$t('searchModule.region')">
                    <el-input
                      :maxlength="11"
                      size="medium"
                      :disabled="true"
                      :hide-required-asterisk="true"
                      v-model.trim="formInline.areaName"
                      auto-complete="off"
                    />
                  </el-form-item>
                  <el-form-item label="身份证号">
                    <el-input
                      :maxlength="11"
                      size="medium"
                      :disabled="true"
                      :hide-required-asterisk="true"
                      v-model.trim="car.idcard"
                      auto-complete="off"
                    />
                  </el-form-item>
                  <el-form-item :label="$t('searchModule.full_name')">
                    <el-input
                      :maxlength="11"
                      size="medium"
                      :disabled="true"
                      :hide-required-asterisk="true"
                      v-model.trim="car.realName"
                      auto-complete="off"
                    />
                  </el-form-item>
                  <el-form-item label="职业" prop="industry">
                    <el-input
                      :maxlength="11"
                      :disabled="true"
                      size="medium"
                      :hide-required-asterisk="true"
                      v-model.trim="formInline.industry"
                      auto-complete="off"
                    >
                    </el-input>
                  </el-form-item>
                  <el-form-item label="用户ID" v-if="formInline.memberId">
                    <el-input
                      :maxlength="11"
                      :disabled="true"
                      size="medium"
                      :hide-required-asterisk="true"
                      v-model.trim="formInline.memberId"
                      auto-complete="off"
                    >
                    </el-input>
                  </el-form-item>
                  <!-- <el-form-item :label="formInline.authTypeTitle"
                                v-if="formInline.identifier && formInline.authTypeTitle">
                    <el-input :maxlength="11"
                              :disabled="true"
                              size="medium"
                              :hide-required-asterisk="true"
                              v-model.trim="formInline.identifier"
                              auto-complete="off">
                    </el-input>
                  </el-form-item> -->
                  <el-form-item label="微信ID">
                    <el-input
                      :maxlength="11"
                      :disabled="true"
                      size="medium"
                      :hide-required-asterisk="true"
                      v-model.trim="formInline.wechatAppAuthId"
                      auto-complete="off"
                      style="width: 280px"
                    >
                    </el-input>
                  </el-form-item>
                  <el-form-item label="支付宝ID">
                    <el-input
                      :maxlength="11"
                      :disabled="true"
                      size="medium"
                      :hide-required-asterisk="true"
                      v-model.trim="formInline.alipayAuthId"
                      auto-complete="off"
                    >
                    </el-input>
                  </el-form-item>
                  <el-form-item label="苹果ID">
                    <el-input
                      :maxlength="11"
                      :disabled="true"
                      size="medium"
                      :hide-required-asterisk="true"
                      v-model.trim="formInline.appleAuthId"
                      auto-complete="off"
                      style="width: 380px"
                    >
                    </el-input>
                  </el-form-item>
                  <!-- <el-form-item label="收入范围" prop='incomeRange'>
                <el-input :maxlength="11" :disabled="true" size="medium" :hide-required-asterisk="true" v-model.trim="formInline.incomeRange" auto-complete="off">
                </el-input>
              </el-form-item> -->
                  <el-form-item label="会员标签" style="display: block">
                    <el-tag
                      v-for="(tag, index) in this.formInline.labelList"
                      :key="index"
                    >
                      {{ tag }}
                    </el-tag>
                  </el-form-item>
                  <el-form-item label="会员等级">
                    <span>{{ formInline.memberLevelName || "" }}</span>
                  </el-form-item>
                  <el-form-item label="信用等级">
                    <span>{{ formInline.creditLevelName || "" }}</span>
                  </el-form-item>
                </div>
              </div>
              <hr />
              <div style="margin-left: 15px">用户信息</div>
              <div class="infoWrapper" style="margin-left: 15px">
                <el-form-item label="账户余额" prop="blance">
                  <el-input
                    :maxlength="11"
                    :disabled="true"
                    size="medium"
                    :hide-required-asterisk="true"
                    v-model.trim="formInline.balance"
                    auto-complete="off"
                  >
                  </el-input>
                </el-form-item>
                <el-form-item label="注册时间" prop="registerTime">
                  <el-input
                    :maxlength="11"
                    :disabled="true"
                    size="medium"
                    :hide-required-asterisk="true"
                    v-model.trim="formInline.registerTime"
                    auto-complete="off"
                  >
                  </el-input>
                </el-form-item>
                <el-form-item label="APP版本">
                  <el-input
                    :maxlength="11"
                    :disabled="true"
                    size="medium"
                    :hide-required-asterisk="true"
                    v-model.trim="formInline.appVersion"
                    auto-complete="off"
                  />
                </el-form-item>
                <el-form-item label="操作系统">
                  <el-input
                    :maxlength="11"
                    :disabled="true"
                    size="medium"
                    :hide-required-asterisk="true"
                    v-model.trim="getDevice"
                    auto-complete="off"
                  />
                </el-form-item>
              </div>
              <hr />
              <div style="margin-left: 15px">绑定认证</div>
              <div class="infoWrapper">
                <div
                  class="content"
                  v-for="(item, index) in carList"
                  :key="index"
                  style="float: left; margin-left: 15px; padding: 0"
                >
                  <div class="content" style="float: left; margin-right: 20px">
                    <span>{{ "所属车辆-" + (index * 1 + 1) + "：" }}</span>
                    <el-button type="text" @click="toDetail(item)">{{
                      item.plateNumber
                    }}</el-button>
                    <span style="margin-left: 20px">
                      {{ item.authStatus == 1 ? "已认证" : "未认证" }}
                    </span>
                    <AuthorityComponent
                      ComponentName="el-button"
                      :permission="[
                        'subPage.view.tabs.baseInfo.button.unbundling',
                      ]"
                      type="text"
                      @click="plateHandle(item)"
                      >解绑
                    </AuthorityComponent>
                  </div>
                  <!-- v-show='authority.tabs["4501000100"].button.unbundling'  -->
                </div>
                <div class="content" style="margin-left: 15px; width: 100%">
                  身份认证状态：{{ car.authStatus == 1 ? "已认证" : "未认证" }}
                </div>
                <div
                  class="f1"
                  style="width: 60%; float: left; margin-left: 35px"
                >
                  <el-row :gutter="12" style="margin-top: 20px">
                    <el-col :span="12">
                      <div>
                        <div class="img">
                          <img
                            :src="car.frontIdcardImage"
                            @click="showBigPic(car.frontIdcardImage)"
                            alt=""
                            style="width: 100%; height: 200px"
                          />
                        </div>
                      </div>
                    </el-col>
                    <el-col :span="12">
                      <div>
                        <div class="img">
                          <img
                            :src="car.backIdcardImage"
                            @click="showBigPic(car.backIdcardImage)"
                            alt=""
                            style="width: 100%; height: 200px"
                          />
                        </div>
                      </div>
                    </el-col>
                  </el-row>
                </div>
              </div>
            </el-form>
          </div>
        </AuthorityComponent>
        <!-- 停车记录 -->
        <AuthorityComponent
          ComponentName="el-tab-pane"
          :permission="['subPage.view.tabs.userParkRecode']"
          label="停车记录"
          name="2"
        >
          <parkRecordTotal
            :authority="authority.tabs['userParkRecode']"
            ref="park"
          ></parkRecordTotal>
        </AuthorityComponent>
        <!-- 支付记录 -->
        <AuthorityComponent
          ComponentName="el-tab-pane"
          :permission="['subPage.view.tabs.userPayment']"
          label="支付记录"
          name="5"
        >
          <Paymentrecords
            :authority="authority.tabs['userPayment']"
            ref="pay"
          ></Paymentrecords>
        </AuthorityComponent>
        <!-- 充值记录 -->
        <AuthorityComponent
          ComponentName="el-tab-pane"
          :permission="['subPage.view.tabs.userChargeList']"
          label="充值记录"
          name="3"
        >
          <arrearsRecordTotal
            :authority="authority.tabs['userChargeList']"
          ></arrearsRecordTotal>
        </AuthorityComponent>
        <!-- 欠费记录 -->
        <AuthorityComponent
          ComponentName="el-tab-pane"
          :permission="['subPage.view.tabs.debtList']"
          label="欠费记录"
          name="4"
        >
          <rechargeTotal
            :authority="authority.tabs['debtList']"
          ></rechargeTotal>
        </AuthorityComponent>
        <!-- 会员等级 -->
        <AuthorityComponent
          ComponentName="el-tab-pane"
          :permission="['subPage.view.tabs.userLevel']"
          label="会员等级"
          name="6"
        >
          <memberLevel :authority="authority.tabs['userLevel']"></memberLevel>
        </AuthorityComponent>
        <!-- 信用等级 -->
        <AuthorityComponent
          ComponentName="el-tab-pane"
          :permission="['subPage.view.tabs.userCredit']"
          label="信用等级"
          name="7"
        >
          <creditLevel :authority="authority.tabs['userCredit']"></creditLevel>
        </AuthorityComponent>
        <!-- 退费记录 -->
        <AuthorityComponent
          ComponentName="el-tab-pane"
          :permission="['subPage.view.tabs.userCredit']"
          label="退款记录"
          name="8"
        >
          <refundRecordQuery
            :authority="authority.tabs['userCredit']"
            ref="refund"
          ></refundRecordQuery>
        </AuthorityComponent>
      </el-tabs>
    </div>
    <transition name="fade">
      <div
        class="mask"
        v-show="bigPic"
        :style="{
          width: '500px',
          height: 'auto',
          margin: '0 auto',
          'z-index': '10000',
          'background-image': picUrl,
        }"
        @click="bigPic = false"
      ></div>
    </transition>
  </div>
</template>
<script>
// import parkRecordTotal from './parkRecordTotal'
// import arrearsRecordTotal from './arrearsRecordTotal'
// import rechargeTotal from './rechargeTotal'
import parkRecordTotal from "./tabpage/totalMember_parkRecordTotal";
import Paymentrecords from "./tabpage/totalMember_Paymentrecords";
import arrearsRecordTotal from "./tabpage/totalMember_arrearsRecordTotal";
import rechargeTotal from "./tabpage/totalMember_rechargeTotal";
import memberLevel from "./tabpage/totalMember_memberLevel";
import creditLevel from "./tabpage/totalMember_creditLevel";
import refundRecordQuery from "./tabpage/refundRecordQuery";
import store from "../../store";
export default {
  name: "memberinformationdetail",
  data() {
    let checkParkNum = (rule, value, callback) => {
      let len = value.length;
      if (!value) {
        callback();
      } else if (len < 3) {
        callback(new Error("至少输入三个字符"));
      } else if (len > 3 && len <= 8) {
        callback();
      } else if (len > 12) {
        callback(new Error("最多输入12个字符"));
      }
    };
    let checkPhone = (rule, value, callback) => {
      if (!/^\d{11}$/.test(value) && value) {
        callback(new Error("手机号格式不正确"));
      } else {
        callback();
      }
    };
    return {
      years: this.$route.query.years,
      regChannel: "",
      memberId: "",
      portraittwo: "./car-pic.png",
      bigPic: false,
      picUrl: "",
      tags: [],
      authority: JSON.parse(sessionStorage.subPageAuthorityMemeber),
      activeName: "1",
      memberInfo: {},
      carList: [],
      index: 0,
      total: 0,
      tableCols: [
        {
          prop: "plateNumber",
          label: this.$t("list.plate_number"),
          width: "",
          formatter: (row, column) => {
            if (row.plateNumber.indexOf("无") != -1) {
              return "无牌车";
            } else {
              return row.plateNumber;
            }
          },
        },
        {
          prop: "parkName",
          label: this.$t("list.park_name"),
          width: "",
        },
        {
          prop: "strEntryTime",
          label: this.$t("list.entry_time"),
          width: "",
        },
        {
          prop: "strExitTime",
          label: this.$t("list.Appearance_time"),
          width: "",
        },
        {
          prop: "parkTime",
          label: this.$t("list.Parking_duration"),
          width: "",
        },
        {
          prop: "shouldPay",
          label: this.$t("list.amount_receivable"),
          width: "",
          formatter: (row) => {
            if (row.shouldPay) {
              return Number(row.shouldPay / 100).toFixed(2);
            } else {
              return "0.00";
            }
          },
        },
        {
          prop: "actualPay",
          label: this.$t("list.Actual_received_amount"),
          width: "",
          formatter: (row) => {
            if (row.actualPay) {
              return Number(row.actualPay / 100).toFixed(2);
            } else {
              return "0.00";
            }
          },
        },
      ],
      tableData: [],
      dateRange: [],
      payTypeList: [],
      parkType: [],
      car: [],
      idcard: "",
      formInline: {},
      rules: {
        plateNumber: [
          {
            validator: checkParkNum,
            trigger: "blur",
          },
        ],
        mobile: [
          {
            validator: checkPhone,
            trigger: "blur",
          },
        ],
        deviceType: [
          {
            validator: checkPhone,
            trigger: "blur",
          },
        ],
      },
      // labelList: []
    };
  },
  watch: {
    authority: {
      handler(ne, ol) {
        let key = Object.keys(ne.subPage.view.tabs)[0];
        this.$nextTick(() => {
          switch (key) {
            case "baseInfo":
              return;
            case "userParkRecode":
              this.activeName = "2";
              return;
            case "userChargeList":
              this.activeName = "3";
              return;
            case "debtList":
              this.activeName = "4";
              break;
            case "userPayment":
              this.activeName = "5";
              break;
            case "userLevel":
              this.activeName = "6";
              break;
            case "userCredit":
              this.activeName = "7";
              break;
            default:
              break;
          }
        });
      },
      immediate: true,
    },
    $route(to, from) {
      if (to.path === "/memberinformationdetail2") {
        this.$route.meta.authority = store.state.menu.button.memberInfo;
      }
    },
  },
  components: {
    parkRecordTotal,
    Paymentrecords,
    arrearsRecordTotal,
    rechargeTotal,
    memberLevel,
    creditLevel,
    refundRecordQuery,
  },
  methods: {
    handleClick(tab, event) {
      switch (tab.name) {
        case "2":
          this.$refs.park.init();
          break;
        case "5":
          this.$refs.pay.searchData();
          break;
        case "8":
          this.$refs.refund.searParkRecordList();
          break;
        default:
      }
    },
    carjump(data) {
      if (data.authStatus == 1) {
        this.$router.push({
          path: "/carValite",
          query: { memberId: data.memberCarauthId },
        });
      }
    },
    // 解绑
    plateHandle(data) {
      this.$confirm('确定要解绑车牌"' + data.plateNumber + '"吗?', this.$t('pop_up.Tips'), {
        confirmButtonText: "解绑",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$axios
            .post("/acb/2.0/car/unbindCar", {
              data: {
                carId: data.carId,
                plateNumber: data.plateNumber,
                memberId: data.memberId,
              },
            })
            .then((res) => {
              if (res.state == 0) {
                this.$message({
                  type: "info",
                  message: "解绑成功！",
                });
                this.information();
                this.certification();
              } else {
                this.$alert(res.desc, this.$t('pop_up.Tips'), {
                  confirmButtonText: this.$t('pop_up.Determine'),
                });
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消解绑",
          });
        });
    },
    showBigPic(url) {
      if (!url) {
        return false;
      }
      this.picUrl = `url("${url}")`;
      this.bigPic = true;
    },
    // 信息
    information() {
      let opt = {
        method: "get",
        url: "/acb/2.0/memberInfo/baseInfo",
        data: {
          memberId: this.memberId,
        },
        success: (res) => {
          this.formInline = res.value;
          if (this.formInline.birthDate) {
            this.formInline.birthDate = this.$moment(
              this.formInline.birthDate
            ).format("YYYY-MM-DD");
          }
          // switch (res.value.authType) {
          //   case 10:
          //     this.formInline.authTypeTitle = "微信ID";
          //     break;
          //   case 5:
          //     this.formInline.authTypeTitle = "支付宝ID";
          //     break;
          //   case 9:
          //     this.formInline.authTypeTitle = "苹果ID";
          //     break;
          //   default:
          //     this.formInline.authTypeTitle = null;
          //     break;
          // }
          this.formInline.wechatAppAuthId = this.formInline.wechatAppAuthId
            ? this.formInline.wechatAppAuthId
            : "无";
          this.formInline.alipayAuthId = this.formInline.alipayAuthId
            ? this.formInline.alipayAuthId
            : "无";
          this.formInline.appleAuthId = this.formInline.appleAuthId
            ? this.formInline.appleAuthId
            : "无";
          this.formInline.balance = (this.formInline.balance / 100).toFixed(2);
        },
      };
      this.$request(opt);
    },
    // 认证
    certification() {
      let opt = {
        method: "get",
        url: "/acb/2.0/memberInfo/authInfo",
        data: {
          memberId: this.memberId,
        },
        success: (res) => {
          // if (res.desc == success) {
          if (res.value.car) {
            this.carList = res.value.car;
          } else {
            this.carList = [];
          }
          if (res.value.idcard) {
            this.car = res.value.idcard;
          }
          // }
        },
      };
      this.$request(opt);
    },
    // 查询用户已绑标签
    // getLabels() {
    //   const opt = {
    //     url: '/acb/2.0/labelManage/labelsByUser',
    //     method: 'get',
    //     data: {
    //       memberId: this.memberId
    //     },
    //     success: res => {
    //       this.labelList = res.value
    //     }
    //   }
    //   this.$request(opt)
    // },
    // 进入详情页
    toDetail(row) {
      if (!store.state.menu.button.carManager) {
        this.$alert("没有查看车辆信息的权限！", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return;
      }
      this.$router.push({
        path: "/VehicleininfoDetail2",
        query: {
          carId: row.carId,
          memberId: row.memberId,
          isAuth: row.authStatus === 1 ? "true" : "false",
        },
      });
    },
  },
  created() {
    if (this.$route.path === "/memberinformationdetail2") {
      this.$route.meta.authority = store.state.menu.button.memberInfo;
    }
    // this.memberId = JSON.parse(this.$route.memberId)
    this.memberId = this.$route.query.memberId;
    this.memberInfo = JSON.parse(this.$route.query.data);
    this.regChannel =
      this.memberInfo.regChannel == 1
        ? "IOS"
        : this.memberInfo.regChannel == 2
        ? "安卓"
        : "公众号";
    // if (this.$route.query.carList) {
    //   this.carList = JSON.parse(this.$route.query.carList);
    // }
    this.information();
    this.certification();
    // this.getLabels()
  },
  mounted() {
    console.log(this.authority);
    console.log(this.authority.tabs);
    // this.information()
    // this.certification()
  },
  activated() {
    this.information();
    this.certification();
  },
  computed: {
    getDevice() {
      const val = this.formInline.deviceType;
      if (!val) {
        return;
      }
      const deviceType = ["", "IOS", "Android"];
      return deviceType[val];
    },
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.t_c {
  text-align: center;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to { /* .fade-leave-active in <2.1.8 */
  opacity: 0;
}

.infoWrapper {
  margin: 20px auto;
  overflow: hidden;
}

.infoWrapper .fl {
  float: left;
  width: 600px;

  .content {
    width: 250px;
    float: left;
    line-height: 60px;
    padding-left: 30px;
  }
}

.infoWrapper .fl:nth-of-type(1) {
  width: 170px;

  .img {
    background: url('./car-pic.png') no-repeat 12px center;
  }
}

.breadcrumb {
  height: 40px;
}

.content {
  padding: 10px;
  overflow: hidden;
}

.mask {
  background: rgba(0, 0, 0, 0) no-repeat center / contain;
  // background: rgba(49,53,65,0.60) no-repeat center/contain;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
}
</style>
<style scoped>
.info {
  width: 565px;
}
.info li {
  overflow: hidden;
  border-left: 1px solid #ebeef5;
  border-top: 1px solid #ebeef5;
}

.info li div {
  float: left;
  min-height: 30px;
  line-height: 30px;
  border-right: 1px solid #ebeef5;
  border-bottom: 1px solid #ebeef5;
}

.title {
  line-height: 50px;
  border: 1px solid #ebeef5;
  border-bottom: 0;
  padding-left: 10px;
  color: skyblue;
}

.item-name {
  width: 100px;
  text-align: right;
}

.item-img {
  width: 281px;
  text-align: center;
  vertical-align: middle;
}

.item-img img {
  width: 80%;
  vertical-align: middle;
}

.item-content {
  width: 180px;
  text-align: center;
}

.balance {
  display: inline-block;
  padding: 2px 15px;
  line-height: 18px;
}

.item-img-wrapper {
  min-height: 124px !important;
  line-height: 124px !important;
  /* // border-bottom: 1px solid #ebeef5; */
}
.showEmp {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  vertical-align: middle;
  width: 65px;
}
.item-img-wrapper .item-name,
.item-img-wrapper .item-content,
.item-img {
  height: 124px !important;
  line-height: 124px !important;
  /* // border-bottom:none!important; */
}
.el-tag {
  margin-left: 10px;
}
.img {
  background: url("car-pic.png") center no-repeat;
}
</style>
