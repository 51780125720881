<template>
  <div>
    <div
      class="searchWrapper"
      @keydown.enter.prevent="
        pageNum = 1;
        searchData();
      "
    >
      <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
      <el-form
        :model="formInline"
        ref="searchWrapper"
        :inline="true"
        label-position="right"
        class="demo-form-inline"
      >
        <div class="col_box">
          <div class="col_left">
            <el-form-item :label="$t('searchModule.plate_number')" prop="plateNumber">
              <el-select
                v-model.trim="formInline.carId"
                filterable
                placeholder=""
                size="15"
              >
                <el-option label="全部" value=""></el-option>
                <el-option
                  :label="item.plateNumber"
                  :value="item.carId"
                  :key="item.carId"
                  v-for="item in carIdList"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('searchModule.region')">
              <!-- <el-select v-model.trim="formInline.areaId" filterable clearable size="8">
            <el-option label="全部" value=""/>
            <el-option
              v-for="item in areaList"
              :key="item.areaId"
              :label="item.areaName"
              :value="item.areaId"/>
          </el-select> -->
              <a-cascader
                ref="cascader"
                @change="handleAreaChange"
              ></a-cascader>
            </el-form-item>
            <el-form-item label="停车场类型">
              <el-select v-model.trim="formInline.parkType" filterable size="8">
                <el-option label="全部" value="" />
                <el-option
                  v-for="item in parkSpinnerList"
                  :key="item.code"
                  :label="item.desc"
                  :value="item.code"
                />
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('searchModule.park_name')">
              <my-component
                ref="parkInput"
                :areaIds="
                  formInline.streetId ? formInline.streetId : formInline.areaId
                "
                :operationId="formInline.operationId"
                @valueChange="completeValue"
                slaveRelations="0,1"
              >
              </my-component>
            </el-form-item>
            <el-form-item :label="$t('searchModule.Entry_and_exit_types')">
              <el-select
                v-model.trim="formInline.entryOrExit"
                filterable
                size="8"
              >
                <el-option
                  :label="item.desc"
                  :value="item.code"
                  :key="item.code"
                  v-for="item in ExitTypeList"
                ></el-option>
              </el-select>
            </el-form-item>
            <!-- <el-form-item :label="$t('searchModule.time_slot')">
          <el-date-picker v-model="date1" type="datetime" placeholder="选择日期" @change="showLog"> </el-date-picker> 至
          <el-date-picker v-model="date2" type="datetime" placeholder="选择日期" @change="showLog"> </el-date-picker>
        </el-form-item> -->
              <el-form-item label="时间">
                <el-date-picker
                  v-model="timeArray"
                  type="datetimerange"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  range-separator="~"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  :default-time="['00:00:00', '23:59:59']"
                >
                </el-date-picker>
                <i class="el-icon-date el-icon-date-custom"></i>
              </el-form-item>
            </div>
            <div class="col_right">
              <AuthorityComponent
                ComponentName="el-button"
                :permission="['subPage.view.tabs.userParkRecode.button.query']"
                type="primary"
                :loading="loading"
                icon="el-icon-search"
                @click="
                  pageNum = 1;
                  searchData();
                "
                >查询
              </AuthorityComponent>
            </div>
          </div>
          <div class="col_box_boder"></div>
          <!-- 如果没有下面按钮就不需要 -->
          <div class="col_box h44">
            <div class="col_left">
              <!-- 其他按钮 -->
            </div>
            <div class="col_right mbd4">
              <AuthorityComponent
                ComponentName="el-button"
                :permission="['subPage.view.tabs.userParkRecode.button.export']"
                type="info"
                :loading="loading"
                icon="el-icon-upload2"
                @click="
                  pageNum = 1;
                  exportFile();
                "
                >导出
              </AuthorityComponent>
            </div>
          </div>

          <!-- <el-button type="primary" @click="pageNum=1;searchData()" :loading="loading">{{ $t('button.search') }}</el-button> -->

          <!-- <el-button type="primary" icon="el-icon-delete" @click="resetForm('searchWrapper')">{{ $t('button.reset') }}</el-button> -->
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper bgFFF paddingB10">
        <el-table v-loading="loading" :data="tableData" style="width: 100%">
          <!--<el-table-column type="index" :label="$t('list.index')" width="70" align='center' ></el-table-column>-->
          <el-table-column
            :prop="item.prop"
            align="center"
            :label="item.label"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
            show-overflow-tooltip
          ></el-table-column>
          <AuthorityComponent
            ComponentName="el-table-column"
            :permission="['subPage.view.tabs.userParkRecode.button.detail']"
            align="center"
            label="操作"
            width="140"
          >
            <template slot-scope="scope">
              <el-button type="text" @click="detailShow(scope.row)">详情</el-button>
            </template>
          </AuthorityComponent>
        </el-table>
        <!--分页器-->
        <div class="pagerWrapper">
          <div class="block">
            <el-pagination
              v-if="total != 0"
              @current-change="handleCurrentChange"
              :current-page="pageNum"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </div>

      <!-- 弹窗 -->
      <!-- <pic-detail :infoSize="4" :infoDetail="parkDetail" :parkRecordIdTable='parkRecordIdTable' :parkRecordIdData= 'parkRecordIdData' :activeName='activeName' :rowData='rowData' :detailFlag="dialogVisible" :tableOrder='tableOrder' @close="dialogVisible=false" :total='total1' :tableOrderdata='tableOrderdata' @getOrder='getOrder' :entryPic='entryPic'
      :exitPic='exitPic'></pic-detail> -->
      <pic-detail
        :infoSize="4"
        :infoDetail="parkDetail"
        :rowData="rowData"
        :detailFlag="dialogVisible"
        :tableOrder="tableOrder"
        :parkRecordIdTable="parkRecordIdTable"
        @close="dialogVisible = false"
        :total="total1"
        :tableOrderdata="tableOrderdata"
        :parkRecordIdData="parkRecordIdData"
        @getOrder="clildrenHandleCurrentChange"
        :entryPic="entryPic"
        :exitPic="exitPic"
        :operationHistoryData="operationHistoryData"
        :operationTableHistory="operationTableHistory"
      ></pic-detail>
    </div>
  </div>
</template>
<script>
import picDetail from "../../../components/picDetail/picDetail";
import {
  dateFormat,
  exportExcelNew,
  setIndex,
} from "../../../common/js/public.js";
import myComponent from "@/components/autocomplete/myautoComponent";
export default {
  name: "totalMember",
  props: ["authority"],
  data() {
    const startTime =
      this.$moment().subtract(30, "d").format("YYYY-MM-DD") + " 00:00:00";
    const endTime = this.$moment().format("YYYY-MM-DD") + " 23:59:59";
    return {
      operationHistoryData: [],
      parkRecordIdData: [],
      carIdList: [],
      ExitTypeList: [],
      entryPic: [],
      exitPic: [],
      activeName: "first",
      total1: 0,
      tableOrderdata: [],
      dialogVisible: false,
      rowData: {},
      parkDetail: [],
      memberInfo: {},
      carList: [],
      total: 0,
      pageSize: 15,
      pageNum: 1,
      loading: false,
      ringChart: {},
      pieChart: {},
      parkRecordIdTable: [
        {
          label: this.$t("list.Refund_method"),
          value: "refundModeName",
        },
        {
          //   label: '支付方式',
          //   value: 'payTypeName'
          // }, {
          label: this.$t("list.Payment_channels"),
          value: "channelTypeDesc",
        },
        {
          label: this.$t("list.Payment_devices"),
          value: "devTypeDesc",
        },
        {
          label: this.$t("list.refund_reason"),
          value: "refundReasonName",
        },
        {
          label: this.$t("list.Actual_appearance_time"),
          value: "actualExitTime",
          formatter: (row) => {
            if (row.actualExitTime) {
              return this.timestampToTime(row.actualExitTime);
            } else {
              return "";
            }
          },
        },
        {
          label: this.$t("list.refund_amount"),
          value: "actualRefundPay",
          formatter: (row, column) => {
            if (row.actualRefundPay) {
              return Number(row.actualRefundPay / 100).toFixed(2);
            } else {
              return "0.00";
            }
          },
        },
        {
          label: this.$t("list.applicant"),
          value: "operatorName",
        },
        {
          label: this.$t("list.Application_Time"),
          value: "createdTime",
        },
        {
          label: this.$t("list.Refund_time"),
          value: "refundTime",
          formatter: (row) => {
            if (row.refundTime) {
              return this.timestampToTime(row.refundTime);
            } else {
              return "";
            }
          },
        },
      ],
      tableOrder: [
        { label: this.$t("list.order_ID"), value: "payOrderId", width: "" },
        { label: this.$t("list.entry_time"), value: "entryTime", width: "" },
        { label: this.$t("list.Appearance_time"), value: "exitTime", width: "" },
        {
          label: this.$t("list.amount_receivable"),
          value: "shouldPay",
          width: "",
          formatter: (row, column) => {
            return row.shouldPay == 0 ? "0元" : row.shouldPay / 100 + "元";
          },
        },
        {
          label: this.$t("list.preferential_amount"),
          value: "agioPay",
          width: "",
          formatter: (row, column) => {
            return row.agioPay == 0 ? "0元" : row.agioPay / 100 + "元";
          },
        },
        {
          label: this.$t("list.Actual_received_amount"),
          value: "money",
          width: "",
          formatter: (row, column) => {
            return row.money == 0 ? "0元" : row.money / 100 + "元";
          },
        },
        { label: this.$t("list.Payment_Type"), value: "paymentTypeDesc", width: "" },
        // { label: "支付方式", value: "payTypeDesc", width: "" },
        { label: this.$t("list.Payment_channels"), value: "channelTypeDesc", width: "" },
        { label: this.$t("list.Payment_devices"), value: "devTypeDesc", width: "" },
        { label: this.$t("list.Payer"), value: "operatorName", width: "" },
        { label: this.$t("list.Payment_yard"), value: "payParkName", width: "" },
        { label: this.$t("list.Third_party_transaction_ID"), value: "tradeNo", width: "" },
      ],
      tableCols: [
        {
          prop: "mobile",
          label: "会员手机号",
        },
        {
          prop: "plateNumber",
          label: this.$t("list.plate_number"),
          width: "",
          formatter: (row, column) => {
            if (row.plateNumber.indexOf("无") != -1) {
              return "无牌车";
            } else {
              return row.plateNumber;
            }
          },
        },
        {
          prop: "areaName",
          label: this.$t("list.region"),
        },
        {
          prop: "parkName",
          label: this.$t("list.park_name"),
        },
        {
          prop: "parkType",
          label: "停车场类型",
          formatter: (row, column) => {
            if (row.parkType) {
              const result = [
                "",
                "路侧平行",
                "路侧垂停",
                "封闭车场",
                "半封闭车场",
              ];
              return result[row.parkType];
            }
          },
        },
        {
          prop: "berthCode",
          label: this.$t("list.Berth_number"),
        },
        {
          prop: "strEntryTime",
          label: this.$t("list.entry_time"),
          width: "",
        },
        {
          prop: "strExitTime",
          label: this.$t("list.Appearance_time"),
          width: "",
        },
        {
          prop: "parkTime",
          label: "停车时长（分钟）",
          width: "",
        },
        // {
        //   prop: 'shouldPay',
        //   label: '应收金额',
        //   width: '',
        //   formatter: (row) => {
        //     if (row.shouldPay) {
        //       return Number((row.shouldPay) / 100).toFixed(2)
        //     } else {
        //       return '0.00'
        //     }
        //   }
        // },
        {
          prop: "actualPay",
          label: "实收金额（元）",
          width: "",
          formatter: (row) => {
            if (row.actualPay) {
              return Number(row.actualPay / 100).toFixed(2);
            } else {
              return "0.00";
            }
          },
        },
        // {
        //   prop: 'payTypeDesc',
        //   label: '支付渠道'
        // },
        // {
        //   label: '支付时间'
        // }
      ],
      tableData: [],
      formInline: {
        entryOrExit: 1,
        startTime: startTime,
        endTime: endTime,
        carId: "",
        areaId: "",
        parkType: "",
      },
      timeArray: [startTime, endTime],
      areaList: [],
      parkSpinnerList: [],
      operationTableHistory: [
        {
          label: this.$t("list.state"),
          value: "statusName",
        },
        {
          label: "处理记录ID",
          value: "parkOrderAppealId",
          width: "180",
        },
        {
          label: this.$t("list.processing_time"),
          value: "handleTime",
          width: "180",
        },
        {
          label: this.$t("list.Processing_personnel"),
          value: "handleOperatorName",
        },
        {
          label: "业务订单类型",
          value: "businessTypeName",
          width: "120",
        },
        {
          label: "更改项",
          value: "appealTypeName",
          width: "120",
        },
        {
          label: "更改初始值",
          value: "originValue",
          width: "120",
        },
        {
          label: "更改更新值",
          value: "currentValue",
          width: "120",
        },
      ],
    };
  },
  methods: {
    // 选择区域
    handleAreaChange() {
      let areaId = "";
      if (this.$refs.cascader) {
        areaId = this.$refs.cascader.getStreetId()
          ? this.$refs.cascader.getStreetId()
          : this.$refs.cascader.getAreaId();
      }
      this.formInline.areaId = areaId;
    },
    // 加载区域列表
    initAreaList() {
      const opt = {
        url: "/acb/2.0/systems/loginUser/initAreaList",
        method: "get",
        data: {},
        success: (res) => {
          this.areaList = res.value.areaList;
        },
      };
      this.$request(opt);
    },
    // 加载停车场类型
    getParkSpinner() {
      const opt = {
        url: "/acb/2.0/park/spinner",
        method: "get",
        data: {},
        success: (res) => {
          this.parkSpinnerList = res.value.parkType;
        },
      };
      this.$request(opt);
    },
    orderAppeal() {
      let opt = {
        url: "/acb/2.0/orderAppeal/getByParkRecordId",
        method: "get",
        data: {
          parkRecordId: this.rowData.parkRecordId,
        },
        success: (res) => {
          if (res.state == 0) {
            this.operationHistoryData = res.value;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        },
      };
      this.$request(opt);
    },
    timestampToTime(timestamp) {
      var date = new Date(timestamp * 1); // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var D =
        date.getDate() < 10 ? "0" + date.getDate() + " " : date.getDate() + " ";
      var h =
        date.getHours() < 10
          ? "0" + date.getHours() + ":"
          : date.getHours() + ":";
      var m =
        date.getMinutes() < 10
          ? "0" + date.getMinutes() + ":"
          : date.getMinutes() + ":";
      var s =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      return Y + M + D + h + m + s;
    },
    completeValue(item) {
      this.formInline.parkId = item.parkId;
      this.formInline.parkName = item.parkName;
    },
    // 出入场类型列表
    getExitType() {
      let opt = {
        url: "/acb/2.0/parkRecord/entryExitType/list",
        method: "get",
        data: {},
        success: (res) => {
          if (res.state == 0) {
            this.ExitTypeList = res.value;
          } else {
            this.$alert("获取出入场类型失败", this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        },
      };
      this.$request(opt);
    },
    // 停车记录详情
    getRsType({ parkRecordId }) {
      let opt = {
        url: "/acb/2.0/parkRecord/" + parkRecordId,
        method: "get",
        // data: this.formInline,
        success: (res) => {
          if (res.state == 0) {
            this.parkDetail = res.value;
            this.getPic(res.value.entryOprNum, res.value.exitOprNum);
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        },
      };
      this.$request(opt);
    },
    // 导出
    exportFile() {
      // let flag = this.showLog();
      // if (flag) {
      //   exportExcelNew('/acb/2.0/member/parkRecord/export', this.formInline);
      // }
      if (!this.showLog()) {
        return;
      }
      this.formInline.startTime = this.timeArray[0];
      this.formInline.endTime = this.timeArray[1];
      exportExcelNew("/acb/2.0/member/parkRecord/export", this.formInline);
    },
    // resetForm (formName) {
    //   const startTime = this.$moment().format('YYYY-MM-DD') + ' 00:00:00'
    //   const endTime = this.$moment().format('YYYY-MM-DD') + ' 23:59:59'
    // 	this.$refs.parkInput.setShowVal('');
    // 	this.formInline = {
    // 		entryOrExit: 1,
    // 		startTime: startTime,
    //     		endTime: endTime,
    // 		carId: '',
    //         memberId: this.memberInfo.memberId
    //   		}
    // },
    // 订单记录
    getOrder(val) {
      let page = this.pageNum || 1;
      let opt = {
        url:
          "/acb/2.0/payOrder/payOrderByParkRecordId/" +
          this.rowData.parkRecordId,
        method: "get",
        data: {
          page: page,
          pageSize: this.pageSize,
          parkRecordId: this.rowData.parkRecordId,
        },
        success: (res) => {
          if (res.state == 0) {
            this.tableOrderdata = res.value;
            this.total1 = res.value.total * 1 || 0;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        },
      };
      this.$request(opt);
    },
    // 获取停车场详情图片
    getPic(entryOprNum, exitOprNum) {
      this.entryPic = [];
      this.exitPic = [];
      if (entryOprNum) {
        let opt = {
          method: "get",
          url: "/acb/2.0/parkRecord/picture/" + entryOprNum,
          success: (res) => {
            if (res.state == 0) {
              this.entryPic = res.value;
            } else {
              this.$alert(res.desc, this.$t('pop_up.Tips'), {
                confirmButtonText: this.$t('pop_up.Determine'),
              });
            }
          },
        };
        this.$request(opt);
      }
      if (exitOprNum) {
        let opt = {
          method: "get",
          url: "/acb/2.0/parkRecord/picture/" + exitOprNum,
          success: (res) => {
            if (res.state == 0) {
              this.exitPic = res.value;
            } else {
              this.$alert(res.desc, this.$t('pop_up.Tips'), {
                confirmButtonText: this.$t('pop_up.Determine'),
              });
            }
          },
        };
        this.$request(opt);
      }
    },
    refundOrder() {
      this.$axios
        .get("/acb/2.0/refundRecord/list", {
          data: {
            page: this.pageNum,
            size: this.pageSize,
            parkRecordId: this.rowData.parkRecordId,
            refundState: 5,
          },
        })
        .then((res) => {
          this.parkRecordIdData = res.value.list;
        });
    },
    detailShow(data) {
      this.rowData = Object.assign({}, data);
      this.dialogVisible = true;
      this.getRsType(data);
      this.getOrder();
      this.refundOrder();
      this.orderAppeal();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.searchData();
    },
    querySearchAsync(queryString, cb) {
      if (queryString.length < 3) {
        cb([]);
        return;
      }
      let opt = {
        method: "get",
        url: "/acb/2.0/parkRecord/plateNumber/list",
        data: {
          part: queryString,
          size: 100,
        },
        success: (res) => {
          if (res.state == 0) {
            res.value.forEach((v) => {
              v.value = v.plateNumber;
            });
            cb(res.value);
          }
        },
      };
      this.$request(opt);
    },
    handleSelect(item) {
      this.formInline.plateNumber = item.plateNumber;
      this.formInline.carId = item.carId;
      this.pageNum = 1;
      // this.searchData();
    },
    // 验证时间
    showLog() {
      if (!this.timeArray) {
        this.$alert("日期不能为空", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return false;
      }
      if (this.timeArray[0] > this.timeArray[1]) {
        this.$alert("结束时间不能小于开始时间", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return false;
      }
      // const startTime = this.$moment(this.timeArray[0]).unix()
      // const endTime = this.$moment(this.timeArray[1]).unix()
      // if ((endTime - startTime) / 3600 / 24 > 31) {
      //   this.$alert('查询时间不能大于31天', this.$t('pop_up.Tips'), {
      //     confirmButtonText: this.$t('pop_up.Determine')
      //   })
      //   return false
      // }
      return true;
    },
    /* 车牌号 */
    getplateNumber() {
      let opt = {
        url: "/acb/2.0/plateNumber",
        method: "get",
        data: {
          part: "",
          size: 100,
        },
        success: (res) => {
          if (res.state == 0) {
            this.parkList = res.value;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        },
      };
      this.$request(opt);
    },
    getCarlist() {
      this.$axios
        .get("/acb/2.0/member/getPlateByMemberId/" + this.memberInfo.memberId)
        .then((res) => {
          this.carIdList = res.value;
        });
    },
    clildrenHandleCurrentChange(val) {
      this.getOrder(val);
    },
    init() {
      this.searchData();
    },
    // 搜索
    searchData() {
      if (!this.showLog()) {
        return;
      }
      const startDate = this.timeArray[0];
      const endDate = this.timeArray[1];
      this.$refs["searchWrapper"].validate((v) => {
        this.loading = true;
        this.flag = false;
        this.$refs.parkInput.setShowVal(this.formInline.parkName);
        this.$axios
          .get("/acb/2.0/member/parkRecord/list", {
            data: {
              page: this.pageNum,
              pageSize: this.pageSize,
              memberId:
                this.formInline.memberId ||
                JSON.parse(this.$route.query.data).memberId,
              startTime: startDate,
              endTime: endDate,
              carId: this.formInline.carId,
              parkId: this.formInline.parkId,
              parkName: this.formInline.parkName,
              entryOrExit: this.formInline.entryOrExit,
              areaId: this.formInline.areaId,
              parkType: this.formInline.parkType,
            },
          })
          .then((res) => {
            this.loading = false;
            if (res.value) {
              this.tableData = setIndex(this.pageNum, res.value.list);
              this.total = res.value.total * 1 || 0;
            } else {
              this.tableData = [];
              this.total = 0;
            }
          })
          .catch((err) => {
            this.loading = false;
          });
      });
    },
  },
  components: {
    picDetail,
    myComponent,
  },
  beforeDestroy() {
    sessionStorage.searchData = JSON.stringify(this.formInline);
    sessionStorage.searchFlag = 1;
  },
  created() {
    this.memberInfo = JSON.parse(this.$route.query.data);
    this.formInline.memberId = this.memberInfo.memberId;
    this.searchData();
    // if (this.$route.query.carList) {
    //   this.carList = JSON.parse(this.$route.query.carList);
    // }
    // if (sessionStorage.searchFlag == 1) {
    //   this.formInline = JSON.parse(sessionStorage.searchData);
    //   this.date1 = this.formInline.startCreateTime;
    //   this.date2 = this.formInline.endCreateTime;
    // }
  },
  computed: {},
  mounted() {
    this.initAreaList();
    this.getParkSpinner();
    this.getExitType();
    // this.searchData();
    this.getCarlist();
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.breadcrumb {
  height: 35px;
}

.content {
  background: #FFFFFF;
  overflow: hidden;

  .chartsWrapper {
    height: 360px;
    margin-bottom: 20px;

    .wrapper {
      background: #FFFFFF;
      border: 1px solid #C0CCDA;
      border-radius: 4px;

      .title {
        font-size: 14px;
        color: #475669;
        padding-left: 17px;
        height: 50px;
        line-height: 50px;
      }
    }
  }

  .searchWrapper {
    overflow: hidden;
    // background: #EFF2F7;
    // padding: 22px 22px 0;
    // margin-bottom: 20px;
  }

  .pagerWrapper {
    text-align: right;
    margin-top: 28px;
    font-size: 12px;

    .export {
      font-size: 12px;

      .iconfont {
        margin-right: 0;
      }
    }
  }
}
</style>
