<template>
  <div class="">
    <!--列表区域-->
    <div class="tableWrapper bgFFF paddingT10 paddingB10">
      <el-table v-loading="loading" :data="tableData" style="width: 100%">
        <!--<el-table-column type="index" :label="$t('list.index')" width="70"></el-table-column>-->
        <el-table-column
          align="center"
          :prop="item.prop"
          :label="item.label"
          :width="item.width"
          v-for="item in tableCols"
          :key="item.prop"
          :formatter="item.formatter"
        ></el-table-column>
      </el-table>
      <!--分页器-->
      <div class="pagerWrapper">
        <div class="block">
          <el-pagination
            v-if="total != 0"
            @current-change="handleCurrentChange"
            :current-page="pageNum"
            :page-size="pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  // setIndex,
  dateFormat,
  exportExcelNew,
  setIndex,
} from "../../../common/js/public.js";
export default {
  props: ["authority"],
  data() {
    const startTime = this.$moment().subtract(30, "d").format("YYYY-MM-DD") + " 00:00:00";
    const endTime = this.$moment().format("YYYY-MM-DD") + " 23:59:59";
    return {
      creditType: [],
      memberInfo: {},
      carList: [],
      index: 0,
      total: 0,
      pageSize: 15,
      pageNum: 1,
      loading: false,
      detailFlag: false,
      isDisabled: true,
      tableCols: [
        {
          prop: "creditType",
          label: "变动类型",
          formatter: (r, c, v) => {
            if (this.creditType.length) {
              return this.creditType[v].desc;
            }
          },
        },
        {
          prop: "createdTime",
          label: "变动时间",
        },
        {
          prop: "creditValue",
          label: "信用值",
          formatter: (r, c, v) => {
            if (v > 0) {
              return "+" + v;
            } else {
              return v;
            }
          },
        },
        {
          prop: "creditLevel",
          label: "当前等级",
          formatter: (r, c, v) => {
            return "V" + v;
          },
        },
      ],
      tableData: [],
      timeArray: [startTime, endTime],
    };
  },
  components: {},
  methods: {
    // 验证时间
    showLog() {
      if (!this.timeArray) {
        this.$alert("日期不能为空", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return false;
      }
      if (this.timeArray[0] > this.timeArray[1]) {
        this.$alert("结束时间不能小于开始时间", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return false;
      }
      return true;
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.searchData();
    },
    getAddOption() {
      this.$axios.get("/acb/2.0/creditLevel/getAddOption").then((res) => {
        this.creditType = res.value.creditTypeEnum;
      });
    },
    // 搜索
    searchData() {
      if (!this.showLog()) {
        return;
      }
      this.loading = true;
      const startDate = this.timeArray[0];
      const endDate = this.timeArray[1];
      let opt = {
        method: "get",
        url: "/acb/2.0/creditLevel/getPageListByMemberId",
        data: {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          memberId: this.memberInfo.memberId,
          entryOrExit: 2,
          startTime: startDate,
          endTime: endDate,
        },
        success: (res) => {
          this.loading = false;
          if (res.state == 0) {
            if (!res.value) {
              return;
            }
            this.tableData = res.value.list;
            this.total = res.value.total * 1 || 0;
          } else {
            this.$alert(res.desc);
          }
        },
        fail: (err) => {
          this.loading = false;
        },
      };
      this.$request(opt);
    },
  },
  created() {
    this.getAddOption();
    this.memberInfo = JSON.parse(this.$route.query.data);
    if (this.$route.query.carList) {
      this.carList = JSON.parse(this.$route.query.carList);
    }
  },
  mounted() {
    this.searchData();
  },
  computed: {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus"></style>
