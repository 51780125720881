var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", {}, [
    _c(
      "div",
      { staticClass: "tableWrapper bgFFF paddingT10 paddingB10" },
      [
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading",
              },
            ],
            staticStyle: { width: "100%" },
            attrs: { data: _vm.tableData },
          },
          _vm._l(_vm.tableCols, function (item) {
            return _c("el-table-column", {
              key: item.prop,
              attrs: {
                align: "center",
                prop: item.prop,
                label: item.label,
                width: item.width,
                formatter: item.formatter,
              },
            })
          }),
          1
        ),
        _c("div", { staticClass: "pagerWrapper" }, [
          _c(
            "div",
            { staticClass: "block" },
            [
              _vm.total != 0
                ? _c("el-pagination", {
                    attrs: {
                      "current-page": _vm.pageNum,
                      "page-size": _vm.pageSize,
                      layout: "total, prev, pager, next, jumper",
                      total: _vm.total,
                    },
                    on: { "current-change": _vm.handleCurrentChange },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }