// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./car-pic.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "\n.info[data-v-79ab40bc] {\n  width: 565px;\n}\n.info li[data-v-79ab40bc] {\n  overflow: hidden;\n  border-left: 1px solid #ebeef5;\n  border-top: 1px solid #ebeef5;\n}\n.info li div[data-v-79ab40bc] {\n  float: left;\n  min-height: 30px;\n  line-height: 30px;\n  border-right: 1px solid #ebeef5;\n  border-bottom: 1px solid #ebeef5;\n}\n.title[data-v-79ab40bc] {\n  line-height: 50px;\n  border: 1px solid #ebeef5;\n  border-bottom: 0;\n  padding-left: 10px;\n  color: skyblue;\n}\n.item-name[data-v-79ab40bc] {\n  width: 100px;\n  text-align: right;\n}\n.item-img[data-v-79ab40bc] {\n  width: 281px;\n  text-align: center;\n  vertical-align: middle;\n}\n.item-img img[data-v-79ab40bc] {\n  width: 80%;\n  vertical-align: middle;\n}\n.item-content[data-v-79ab40bc] {\n  width: 180px;\n  text-align: center;\n}\n.balance[data-v-79ab40bc] {\n  display: inline-block;\n  padding: 2px 15px;\n  line-height: 18px;\n}\n.item-img-wrapper[data-v-79ab40bc] {\n  min-height: 124px !important;\n  line-height: 124px !important;\n  /* // border-bottom: 1px solid #ebeef5; */\n}\n.showEmp[data-v-79ab40bc] {\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  display: inline-block;\n  vertical-align: middle;\n  width: 65px;\n}\n.item-img-wrapper .item-name[data-v-79ab40bc],\n.item-img-wrapper .item-content[data-v-79ab40bc],\n.item-img[data-v-79ab40bc] {\n  height: 124px !important;\n  line-height: 124px !important;\n  /* // border-bottom:none!important; */\n}\n.el-tag[data-v-79ab40bc] {\n  margin-left: 10px;\n}\n.img[data-v-79ab40bc] {\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") center no-repeat;\n}\n", ""]);
// Exports
module.exports = exports;
