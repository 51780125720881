<template>
  <div>
    <!--搜索条件区域-->
    <div class="searchWrapper" @keydown.enter.prevent="searchData">
      <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
      <el-form
        :inline="true"
        label-position="right"
        :rules="rules"
        ref="searchForm"
        :model="formInline"
        class="demo-form-inline"
      >
        <div class="col_box">
          <div class="col_left">
            <el-form-item :label="$t('searchModule.Date_search')">
              <el-date-picker
                v-model="timeArray"
                type="datetimerange"
                range-separator="至"
                value-format="yyyy-MM-dd HH:mm:ss"
                :default-time="['00:00:00', '23:59:59']"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              >
              </el-date-picker
              ><i class="el-icon-date el-icon-date-custom"></i>
            </el-form-item>
            <el-form-item :label="$t('searchModule.Payment_channels')">
              <el-select
                v-model.trim="formInline.payType"
                filterable
                placeholder=""
                size="10"
              >
                <el-option label="全部" value=""></el-option>
                <el-option
                  :label="pay.channelTypeDesc"
                  :value="pay.channelType"
                  :key="pay.channelType"
                  v-for="pay in payTypeList"
                ></el-option>
              </el-select>
            </el-form-item>
            <!-- <el-form-item label="充值时间">
			<el-date-picker v-model.trim="date1" type="datetime" placeholder="选择日期"> </el-date-picker> 至
			<el-date-picker v-model.trim="date2" type="datetime" placeholder="选择日期">  </el-date-picker>
		  </el-form-item> -->
          </div>
          <div class="col_right">
            <AuthorityComponent
              ComponentName="el-button"
              :permission="['subPage.view.tabs.userChargeList.button.query']"
              type="primary"
              :loading="loading"
              @click="
                pageNum = 1;
                searchData();
              "
              icon="el-icon-search"
              >查询
            </AuthorityComponent>
            <!-- <el-button type="primary" icon="el-icon-delete" @click="resetForm('searchForm')">清空</el-button> -->
          </div>
        </div>
        <div class="col_box_boder"></div>
        <div class="col_box h44">
          <div class="col_left"></div>
          <div class="col_right mbd4">
            <AuthorityComponent
              ComponentName="el-button"
              :permission="['subPage.view.tabs.userChargeList.button.export']"
              :loading="loading"
              icon="el-icon-upload2"
              @click="
                pageNum = 1;
                exportFile();
              "
              >导出
            </AuthorityComponent>
          </div>
        </div>
        <!--<el-button type="primary" icon="plus" @click="">{{ $t('button.addto') }}</el-button>-->
      </el-form>
    </div>
    <!--列表区域-->
    <div class="tableWrapper bgFFF">
      <el-table
        border
        v-loading="loading"
        :data="tableData"
        style="width: 100%"
      >
        <!--<el-table-column type="index" :label="$t('list.index')" width="70"></el-table-column>-->
        <el-table-column
          align="center"
          :prop="item.prop"
          :label="item.label"
          :width="item.width"
          v-for="item in tableCols"
          :key="item.prop"
          :formatter="item.formatter"
        ></el-table-column>
      </el-table>
    </div>
    <!--分页器-->
    <div class="pagerWrapper">
      <div class="block">
        <el-pagination
          v-if="total != 0"
          @current-change="handleCurrentChange"
          :current-page="pageNum"
          :page-size="pageSize"
          layout="total, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import {
  dateFormat,
  exportExcelNew,
  setIndex,
} from "../../../common/js/public.js";
export default {
  props: ["authority"],
  data() {
    const startTime =
      this.$moment().subtract(30, "d").format("YYYY-MM-DD") + " 00:00:00";
    const endTime = this.$moment().format("YYYY-MM-DD") + " 23:59:59";
    let checkParkNum = (rule, value, callback) => {
      let len = value.length;
      if (!value) {
        callback();
      } else if (len < 3) {
        callback(new Error("至少输入三个字符"));
      } else if (len > 3 && len <= 8) {
        callback();
      } else if (len > 12) {
        callback(new Error("最多输入12个字符"));
      }
    };
    let checkPhone = (rule, value, callback) => {
      if (!/^\d{11}$/.test(value) && value) {
        callback(new Error("手机号格式不正确"));
      } else {
        callback();
      }
    };
    return {
      activeName: "1",
      ExitTypeList: [],
      memberInfo: {},
      total: 0,
      carList: [],
      index: 0,
      date1: startTime,
      date2: endTime,
      plateNumber: "",
      pageSize: 15,
      pageNum: 1,
      loading: false,
      detailFlag: false,
      isDisabled: true,
      areaList: [],
      parkList: [],
      berthList: [],
      tableCols: [
        {
          prop: "payChannelDesc",
          label: this.$t("list.Payment_channels"),
          width: "",
        },
        {
          prop: "money",
          label: "充值金额",
          width: "",
          formatter: (row) => {
            if (row.money) {
              return Number(row.money / 100).toFixed(2);
            } else {
              return "0.00";
            }
          },
        },
        {
          prop: "payTime",
          label: "支付日期",
        },
      ],
      tableOrder: [
        {
          label: "支付来源",
          value: "devTypeDesc",
        },
        {
          label: this.$t("list.payment_time"),
          value: "payTime",
        },
        {
          label: "支付方式",
          value: "payTypeDesc",
        },
        {
          label: "已支付金额",
          value: "money",
          formatter: (row) => {
            if (row.money) {
              return Number(row.money / 100).toFixed(2);
            } else {
              return "0.00";
            }
          },
        },
        {
          label: "支付流水号",
          value: "paymentId",
        },
      ],
      tableData: [],
      dateRange: [],
      payTypeList: [],
      parkType: [],
      formInline: {
        entryOrExit: 1,
        payType: "",
        startTime: "",
        endTime: "",
      },
      rules: {
        plateNumber: [
          {
            validator: checkParkNum,
            trigger: "blur",
          },
        ],
        mobile: [
          {
            validator: checkPhone,
            trigger: "blur",
          },
        ],
      },
      timeArray: [startTime, endTime],
    };
  },
  methods: {
    timestampToTime(timestamp) {
      var date = new Date(timestamp * 1); // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var D =
        date.getDate() < 10 ? "0" + date.getDate() + " " : date.getDate() + " ";
      var h =
        date.getHours() < 10
          ? "0" + date.getHours() + ":"
          : date.getHours() + ":";
      var m =
        date.getMinutes() < 10
          ? "0" + date.getMinutes() + ":"
          : date.getMinutes() + ":";
      var s =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      return Y + M + D + h + m + s;
    },
    // 获取支付方式
    // getPay () {
    // 	let opt = {
    // 		method: 'get',
    // 		url: '/acb/2.0/member/spinner',
    // 		data: {
    // 		},
    // 		success: (res) => {
    // 			if (res.state == 0) {
    // 				this.payTypeList = res.value.payTypeEnum;
    // 			} else {
    // 				this.$alert(res.desc, this.$t('pop_up.Tips'), {
    // 					confirmButtonText: this.$t('pop_up.Determine')
    // 				});
    // 			}
    // 		}
    // 	};
    // 	this.$request(opt);
    // },
    getPay() {
      this.$axios
        .get("/acb/2.0/payOrder/payChannelTypeDictList")
        .then((res) => {
          if (res.state == 0) {
            this.payTypeList = res.value || [];
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },

    /* 停车场 */
    parkData() {
      if (this.formInline.areaId) {
        this.getChildrenArea();
      }
      this.$axios
        .get("/acb/2.0/systems/loginUser/getParkName", {
          data: {
            page: 0,
            pageSize: 0,
            operationId: this.formInline.operationId,
            areaIds:
              this.formInline.areaId2 != ""
                ? this.formInline.areaId
                : this.formInline.areaId2,
            parkName: this.formInline.parkName,
          },
        })
        .then((res) => {
          this.parkList = res.value.list;
        });
    },
    querySearchAsync(queryString, cb) {
      this.loading = false;
      if (queryString.length < 3) {
        cb([]);
        return;
      }
      let opt = {
        method: "get",
        url: "/acb/2.0/parkRecord/plateNumber/list",
        data: {
          part: queryString,
          size: 100,
        },
        success: (res) => {
          if (res.state == 0) {
            res.value.forEach((v) => {
              v.value = v.plateNumber;
            });
            cb(res.value);
          }
        },
      };
      this.$request(opt);
    },
    handleSelect(item) {
      this.loading = false;
      this.formInline.plateNumber = item.plateNumber;
      this.pageNum = 1;
      this.searParkRecordList();
    },
    // 导出
    exportFile() {
      // let flag = this.showLog();
      // if (flag) {
      //   exportExcelNew('/acb/2.0/member/recharge/export', this.formInline);
      // }
      if (!this.showLog()) {
        return;
      }
      this.formInline.startTime = this.timeArray[0];
      this.formInline.endTime = this.timeArray[1];
      exportExcelNew("/acb/2.0/member/recharge/export", this.formInline);
    },
    // 处理操作相关动作
    ctrlData(command, index, obj) {
      //				let url;
      if (command == "wf") {
        this.downloadSys(obj);
      } else if (command == "df") {
        this.downloadChannel(obj);
      } else if (command == "differ") {
        this.downloadDif(obj);
        //				this.$router.push({
        //					path: url,
        //					query: obj
        //				});
      }
    },
    // 验证时间
    showLog() {
      if (!this.timeArray) {
        this.$alert("日期不能为空", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return false;
      }
      if (this.timeArray[0] > this.timeArray[1]) {
        this.$alert("结束时间不能小于开始时间", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return false;
      }
      // const startTime = this.$moment(this.timeArray[0]).unix()
      // const endTime = this.$moment(this.timeArray[1]).unix()
      // if ((endTime - startTime) / 3600 / 24 > 31) {
      // 	this.$alert('查询时间不能大于31天', this.$t('pop_up.Tips'), {
      // 		confirmButtonText: this.$t('pop_up.Determine')
      // 	})
      // 	return false
      // }
      return true;
    },
    handleTab(tab) {
      this.parkData();
      this.getParkSpinner();
      this.searchData();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.searchData();
    },
    // 搜索
    searchData() {
      if (!this.showLog()) {
        return;
      }
      this.loading = true;
      const startDate = this.timeArray[0];
      const endDate = this.timeArray[1];
      let opt = {
        method: "get",
        url: "/acb/2.0/member/recharge/list",
        data: {
          page: this.pageNum,
          pageSize: this.pageSize,
          memberId: this.memberInfo.memberId,
          payType: this.formInline.payType,
          startTime: startDate,
          endTime: endDate,
        },
        success: (res) => {
          this.loading = false;
          if (res.state == 0) {
            this.tableData = setIndex(this.pageNum, res.value.list);
            this.total = res.value.total * 1 || 0;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        },
        fail: (err) => {
          this.loading = false;
        },
      };
      this.$request(opt);
    },
    // 获取默认情况下的各种下拉列表
    getParkSpinner() {
      this.$axios.get("/acb/2.0/park/spinner").then((res) => {
        for (let i = 0; i < res.value.parkType.length; i++) {
          this.parkType.push(res.value.parkType[i]);
        }
        // for (let i = 0; i < res.value.parkCommercialStatus.length; i++) {
        //   this.parkCommercialStatus.push(res.value.parkCommercialStatus[i])
        // }
      });
    },
  },
  created() {
    this.memberInfo = JSON.parse(this.$route.query.data);
    this.formInline.memberId = this.memberInfo.memberId;
    if (this.$route.query.carList) {
      this.carList = JSON.parse(this.$route.query.carList);
    }
  },
  mounted() {
    this.searchData();
    this.getPay();
  },
  computed: {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.breadcrumb {
  height: 35px;
}

.content {
  background: #FFFFFF;
  overflow: hidden;

  .chartsWrapper {
    height: 360px;
    margin-bottom: 20px;

    .wrapper {
      background: #FFFFFF;
      border: 1px solid #C0CCDA;
      border-radius: 4px;

      .title {
        font-size: 14px;
        color: #475669;
        padding-left: 17px;
        height: 50px;
        line-height: 50px;
      }
    }
  }

  .searchWrapper {
    overflow: hidden;
    // background: #EFF2F7;
    // padding: 22px 22px 0;
    // margin-bottom: 20px;
  }

  .pagerWrapper {
    text-align: right;
    margin-top: 28px;
    font-size: 12px;

    .export {
      font-size: 12px;

      .iconfont {
        margin-right: 0;
      }
    }
  }
}
</style>
<style scoped>
.info {
  width: 505px;
}

.member-basic-info {
  width: 505px;
}

.info li {
  overflow: hidden;
  border-left: 1px solid #ebeef5;
  border-top: 1px solid #ebeef5;
}

.info li div {
  float: left;
  min-height: 30px;
  line-height: 30px;
  border-right: 1px solid #ebeef5;
  border-bottom: 1px solid #ebeef5;
}

.title {
  line-height: 50px;
  border: 1px solid #ebeef5;
  border-bottom: 0;
  padding-left: 10px;
  color: skyblue;
}

.item-name {
  width: 100px;
  text-align: right;
}

.item-img {
  width: 250px;
  text-align: center;
  vertical-align: middle;
}

.item-img img {
  width: 80%;
  vertical-align: middle;
}

.item-content {
  width: 150px;
  text-align: center;
}

.balance {
  display: inline-block;
  color: white;
  padding: 2px 15px;
  line-height: 18px;
}

.item-img-wrapper {
  min-height: 124px !important;
  line-height: 124px !important;
}

.item-img-wrapper .item-name,
.item-img-wrapper .item-content,
.item-img {
  height: 124px !important;
  line-height: 124px !important;
}
</style>
